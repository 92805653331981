import React from "react";
import { Navigate } from "react-router-dom";

//Main Index
import Main from "../pages/Home/indexMain";

//About page
import PageAboutus from "../pages/Pages/About/PageAboutus";

//Blog / research pages
import PageBlogList from "../pages/Pages/Research/PageResearchList";
import ResearchDetail from "../pages/Pages/Research/ResearchDetails";

//Case Study pages
import AllCases from "../pages/Pages/CaseStudy/AllCases";
import CaseDetail from "../pages/Pages/CaseStudy/CaseDetail";

//Contact page
import PageContact from "../pages/Pages/Contact/PageContact";

//Startup Stories & other pages
import StartupStories from "../pages/Home/StartupStoriesDetails";
import TermCondition from "../pages/Pages/TermCondition/TermCondition";
import Privacy from "../pages/Pages/Privacy/Privacy";

const routes = [
  { path: "/about", component: <PageAboutus /> },

  //Page Blog / research pages
  {
    path: "/research",
    component: <PageBlogList />,
    isTopbarDark: true,
  },
  {
    path: "/research-details",
    component: <ResearchDetail />,
    isTopbarDark: true,
  },

  //Page Case Study
  { path: "/all-cases", component: <AllCases /> },
  {
    path: "/page-case-detail",
    component: <CaseDetail />,
    isTopbarDark: true,
  },

  //Page Contact
  {
    path: "/contact",
    component: <PageContact />,
    isTopbarDark: true,
  },
  //Startup Stories & other pages
  {
    path: "/startup-stories",
    component: <StartupStories />,
    isTopbarDark: true,
  },
  {
    path: "/terms-and-conditions",
    component: <TermCondition />,
    isTopbarDark: true,
  },
  {
    path: "/privacy",
    component: <Privacy />,
    isTopbarDark: true,
  },
  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
  { path: "/home", component: <Main />, isTopbarDark: true },
];

export default routes;
