import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-light d-table w-100"
          // style={{ background: `url(${bgImg}) center center` }}
        >
          {/* <div className="bg-overlay"></div> */}
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title title-dark text-primary mb-0">
                    Privacy Statement
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row className=" justify-content-center">
              <div>
                <p className="text-muted mb-0">
                  We know that you care about how your data is used, and we take
                  your privacy very seriously. By using iAastha, you are
                  accepting the practices outlined in this policy.
                </p>
              </div>
              <div>
                <h4 className="mt-4">Data we collect may include:</h4>
                <p className="text-muted mb-0">
                  User’s public name,age, skype ID, email address, phone number
                  and user’s unique ID which user provides to us. Text, images,
                  files that you send to the bot.
                </p>
              </div>

              <div>
                <h4 className="mt-4">
                  We may use collected data for following purposes:
                </h4>
                <p className="text-muted mb-0 ">
                  User’s unique ID for skype is stored to message him/her. Skype
                  ID, name is stored for our backend agents to see who they are
                  communicating with and for registration.
                </p>
                <p className="text-muted mb-0 mt-3">
                  Please contact us at&nbsp;
                  <a href="mailto:it@iAastha.com" className="text-danger">
                    &nbsp;it@iAastha.com
                  </a>{" "}
                  &nbsp;to report abuse & requests to access or delete personal
                  information.
                </p>
                <p className="text-muted mb-0 mt-3">
                  We do not sell your information to anyone. We don’t encrypt
                  the data stored on our servers, but still it is safe. Our
                  iAastha is enabled by Microsoft Bot Framework. The Microsoft
                  Bot Framework is a set of web-services that enable intelligent
                  services and connections using conversation channels you
                  authorize. As a service provider, Microsoft will transmit
                  content you provide to our bot in order to enable the service.
                  For more information about Microsoft privacy policies please
                  see their privacy statement here. In addition, your
                  interactions with this bot are also subject to the
                  conversational channel’s applicable terms of use, privacy and
                  data collection policies. To report abuse when using a bot
                  that uses the Microsoft Bot Framework to Microsoft, please
                  visit the Microsoft Bot Framework website at botframework.com
                  and use the “Report Abuse” link in the menu to contact
                  Microsoft.
                </p>
                <p className="text-muted mb-0 mt-3">
                  Privacy policy may change. Using iAastha is subject to Terms
                  and Privacy policy.
                </p>
                <p className="text-muted mb-0 mt-3">
                  This Privacy Policy is effective as of 21st Feb 2019
                </p>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Privacy;
