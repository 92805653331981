// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

//Import Images
import client1 from "../../assets/images/our client/botsupply.png";
import client2 from "../../assets/images/our client/moxci.png";
import client3 from "../../assets/images/our client/tablepop.png";
import client4 from "../../assets/images/our client/billtrim.png";
import client5 from "../../assets/images/our client/atmos.png";
import client6 from "../../assets/images/our client/airbills.png";
import botsupply from "../../assets/images/work/botsupply.jpeg";
import moxci from "../../assets/images/work/moxci.jpeg";
import tablepop from "../../assets/images/work/tablepop.jpeg";
import atmos from "../../assets/images/work/atmos.jpeg";
import airBills from "../../assets/images/work/airBills.jpeg";

class Testi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          id: 1,
          image: botsupply,
          name: "F Stasi",
          desc: "They have given us peace of mind so that we can focus on our customers and other things which matter most to our business. We have enjoyed our association with iAastha for several years, they are highly qualified and confident to handle all the key technology related matters efficiently..",
        },
        {
          id: 6,
          image: airBills,
          name: "D. Desai",
          desc: "iAastha has probably the best engineers out there in the market. Highly recommended for startups seeking skilled engineers who genuinely understand the challenges and opportunities in the startup landscape.",
        },

        {
          id: 3,
          image: moxci,
          name: "Femi S",
          desc: "iAastha developed our video calling platform, they applied innovative thinking and problem solving, what I appreciated most was their willingness to be flexible in the various iterations needed to get the work done. They work very hard and put a lot of effort into making a working product.",
        },
        {
          id: 4,
          image: atmos,
          name: "R Sutaria",
          desc: "Team worked on our data ingestion platform where they reduced the platform's ingestion time by 10X. The engagement of the team with our product has been good. We are satisfied with the work delivered.",
        },
        {
          id: 2,
          image: tablepop,
          name: "Vel M",
          desc: "The Team at IAastha Research and Consulting did a good job building my platform. They are easy to communicate with and are equipped at dealing with the technical issues and concerns I have regarding the platform",
        },
      ],
      cols: 4,
      step1: true,
      step2: false,
    };
    this.updateWindowSize.bind(this);
  }

  updateWindowSize = () => {
    if (window.outerWidth >= 1230) {
      this.setState({ itemCount: 4, cols: 4 });
    } else if (window.outerWidth >= 970 && window.outerWidth < 1230) {
      this.setState({ itemCount: 2, cols: 6 });
    } else if (window.outerWidth <= 970) {
      this.setState({ itemCount: 1, cols: 12 });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowSize);
    this.updateWindowSize();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pb-0">
          <Container>
            <Row className="justify-content-center">
              <div className="text-center">
                {/* render Section title */}
                <h6 className="col-12 text-center">
                  We believe in building each other and hence
                </h6>
                <SectionTitle
                  title="Work with some amazing partners"
                  desc=" that can provide everything you need to generate awareness, drive traffic, connect."
                />
              </div>
            </Row>

            <Row>
              <Col className="mt-4 ">
                <div id="customer-testi" className="tiny-five-item">
                  <Row>
                    {this.state.step1 === true
                      ? this.state.clients.map((client, key) =>
                          key >= 0 && key <= 2 ? (
                            <Col
                              key={key}
                              md={this.state.cols}
                              className="mb-1"
                            >
                              <div className="tiny-slide text-center">
                                <div className="client-testi rounded shadow m-2 p-4">
                                  <img
                                    src={client.image}
                                    className="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt=""
                                  />
                                  <p className="text-dark mt-4">
                                    " {client.desc} "
                                  </p>
                                  <h6 className="text-muted">
                                    - {client.name}
                                  </h6>
                                </div>
                              </div>
                            </Col>
                          ) : null,
                        )
                      : this.state.clients.map((client, key) =>
                          key >= 3 && key <= 4 ? (
                            <Col
                              key={key}
                              md={this.state.cols}
                              className="mb-1"
                            >
                              <div className="tiny-slide text-center">
                                <div className="client-testi rounded shadow m-2 p-4">
                                  <img
                                    src={client.image}
                                    className="img-fluid avatar avatar-ex-sm mx-auto"
                                    alt=""
                                  />
                                  <p className="text-dark mt-4">
                                    " {client.desc} "
                                  </p>
                                  <h6 className="text-muted">
                                    - {client.name}
                                  </h6>
                                </div>
                              </div>
                            </Col>
                          ) : null,
                        )}
                  </Row>
                </div>
                {/* </div> */}
                <div className="tns-nav">
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({ step1: true, step2: false });
                    }}
                    className={
                      this.state.step1 ? "tns-nav-active" : "tns-nav-inactive"
                    }
                  ></button>
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({ step1: false, step2: true });
                    }}
                    className={
                      this.state.step2 ? "tns-nav-active" : "tns-nav-inactive"
                    }
                  ></button>
                </div>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testi;
