import React from "react";
import { Container, Row, Col } from "reactstrap";
//import Images

import airBills from "../../assets/images/our client/airbills.png";
import billtrim from "../../assets/images/our client/billtrim.png";
import botsupply from "../../assets/images/our client/botsupply.png";
import ekhoo from "../../assets/images/our client/ekhoo.png";
import jatana from "../../assets/images/our client/jatana.png";
import neuralspace from "../../assets/images/our client/neuralspace.png";
import viralget from "../../assets/images/our client/viralGet.png";
import moxci from "../../assets/images/our client/moxci.png";
import { useLocation } from "react-router-dom";

const Clients = () => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  const partnersData = [
    {
      id: 1,
      img: botsupply,
    },
    {
      id: 2,
      img: moxci,
    },
    {
      id: 3,
      img: billtrim,
    },
    {
      id: 4,
      img: viralget,
    },
    {
      id: 5,
      img: ekhoo,
    },
    // {
    //   id: 6,
    //   img: viralget,
    // },
    // {
    //   id: 7,
    //   img: neuralspace,
    // },
    {
      id: 8,
      img: airBills,
    },
  ];
  return (
    <React.Fragment>
      <section
        className={` ${
          !isAboutPage ? "py-4 border-bottom border-top" : "pt-4"
        }`}
      >
        <Container>
          <Row className="justify-content-center">
            {partnersData.map((item, key) => (
              <Col key={key} lg={2} md={2} className=" text-center py-4 ">
                <img src={item.img} className="avatar avatar-ex-sm" alt="" />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clients;
