import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import StartupStoriesJson from "./StartupStories.json";

class StartupStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null,
      blogsDetails: StartupStoriesJson,
    };
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);

    // JSON data defined here
    const blogs = StartupStoriesJson;

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const blogId = queryParams.get("id");

    if (blogId) {
      console.log(blogId, "blogId");

      // Find the corresponding blog from your data
      const blog = blogs.find((blog) => blog.id === parseInt(blogId));

      if (blog) {
        this.setState({ blog });
      }
    }
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document
          .querySelector(".settingbtn")
          ?.classList.add("btn-soft-primary");
      }
    }
  };
  render() {
    const { blog } = this.state;

    if (!blog) {
      return <p>Loading...</p>; // Show loading or error message if blog not found
    }
    return (
      <React.Fragment>
        <section className="bg-half">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={10}>
                <div className="section-title">
                  <div className="text-center">
                    <h4 className="title mb-4">{blog.title}</h4>
                    <img
                      src={require(`../../assets/images/work/${blog.image}`)}
                      className="img-fluid rounded-md shadow-md mb-2"
                      alt={blog.title}
                    />
                  </div>
                  {/* Render content dynamically */}
                  {blog.content.map((item, index) => {
                    switch (item.type) {
                      case "paragraph":
                        return (
                          <div key={index}>
                            {item.title && (
                              <h5 className="pt-4">{item.title}</h5>
                            )}
                            <p className=" mb-0 mt-2">{item.text}</p>
                            {item.link && (
                              <Link
                                to={item.link}
                                target="_blank"
                                className="pt-6"
                              >
                                {item.link}
                              </Link>
                            )}
                            {item.items && item.items.length > 0 && (
                              <div>
                                {item.items.map((subItem, subIndex) => (
                                  <div key={subIndex} className="mt-2">
                                    {subItem.boldText && (
                                      <strong>{subItem.boldText}&nbsp;</strong>
                                    )}
                                    <span>{subItem.normalText}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      case "list":
                        return (
                          <div key={index}>
                            {item.parentTitle && (
                              <h5 className="mt-4">{item.parentTitle}</h5>
                            )}
                            {item.subTitle && <h6>{item.subTitle}</h6>}
                            <div className="">
                              {item.items &&
                                item.items.length > 0 &&
                                item.items.map((subItem, subIndex) => (
                                  <div key={subIndex} className="mt-2">
                                    {subItem.boldText && (
                                      <strong>{subItem.boldText}&nbsp;</strong>
                                    )}
                                    <span>{subItem.normalText}</span>
                                    {subItem.link && (
                                      <Link
                                        to={subItem.link}
                                        target="_blank"
                                        className="pt-6"
                                        style={{
                                          wordWrap: "break-word", // Allows long words to be broken and wrap to the next line
                                          whiteSpace: "normal", // Ensures the text wraps normally
                                          display: "inline-block", // Ensures the link behaves like an inline element but allows wrapping
                                          maxWidth: "100%", // Ensures the link does not exceed the width of its container
                                        }}
                                      >
                                        {subItem.link}
                                      </Link>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        );
                      case "image":
                        return (
                          item.url && (
                            <div key={index} className="text-center mt-4">
                              <img
                                src={require(
                                  `../../assets/images/work/${item.url}`,
                                )}
                                className="img-fluid rounded-md shadow-md"
                                alt={item.caption}
                              />
                              <p className=" pt-2">{item.caption}</p>
                            </div>
                          )
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs={12} className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-3">Read More</h4>
                </div>
              </Col>
            </Row>

            <Row>
              {this.state.blogsDetails &&
                this.state.blogsDetails
                  .filter((blogs) => blogs.id !== blog.id)
                  .slice(0, 3) // Show only 3 blogs
                  .map((blog1, key) => (
                    <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                      <Card
                        className="border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden h-100"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div style={{ height: "14rem", overflow: "hidden" }}>
                          <img
                            src={require(
                              `../../assets/images/work/${blog1.image}`,
                            )}
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="work"
                          />
                        </div>
                        <CardBody
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                          }}
                        >
                          <div className="content" style={{ flexGrow: 1 }}>
                            <h5 className="mt-3">
                              <h5 className="text-dark title">
                                <a
                                  href={`/startup-stories?id=${blog1.id}`}
                                  className="text-dark"
                                  onClick={() => window.location.reload()}
                                >
                                  {blog1.title}
                                </a>
                              </h5>
                            </h5>
                          </div>
                          <a
                            href={`/startup-stories?id=${blog1.id}`}
                            className="text-primary readmore mt-auto"
                            onClick={() => window.location.reload()}
                          >
                            Read More
                            <i className="uil uil-angle-right-b align-middle"></i>
                          </a>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default StartupStories;
