/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef, react/no-unescaped-entities */
/*eslint-disable-next-line no-undef */

import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "../../../../node_modules/react-image-lightbox/style.css";

//Fade in effect
import FadeIn from "react-fade-in";
//Import Json Data
import CaseStudyJson from "./CaseStudy.json";

class AllCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: CaseStudyJson,
      displayCategory: "All",
      photoIndex: 0,
      isOpen: false,
    };
    this.setCategory.bind(this);
  }

  setCategory(category) {
    this.setState({
      displayCategory: category,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light");
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu")?.classList.add("nav-light");
    });
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document
        .querySelector(".settingbtn")
        ?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <React.Fragment>
        <section
          className="section bg-light d-table w-100"
          // style={{
          //   background: `url(${bgImg}) center center`,
          //   backgroundSize: "cover",
          //   background:
          //   "linear-gradient(135deg, #6a11cb -50%, #80d0c7 50%, #2575fc 100%)",
          // }}
        >
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title title-dark text-primary mb-0">
                    All Cases
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="">
          <Container>
            <Row id="grid" className="mt-4 justify-content-center">
              {this.state.blogs.map(({ title, imageUrl, summary, id }, key) => (
                <Col
                  lg={8}
                  md={12}
                  xs={12}
                  key={key}
                  className="spacing picture-item "
                >
                  <FadeIn delay={100}>
                    <Card
                      className=" border-0 work-container work-primary work-classic shadow rounded-md overflow-hidden"
                      // style={{ height: "38rem" }}
                    >
                      <div className="content">
                        <p className="mt-2" style={{ paddingLeft: "1.5rem" }}>
                          <h4 className="text-dark">{title}</h4>
                        </p>
                      </div>
                      <img
                        src={require(`../../../assets/images/blog/${imageUrl}`)}
                        className="img-fluid"
                        // style={{ height: "20rem", width: "30rem" }}
                        alt="work"
                      />
                      <CardBody>
                        <div className="content">
                          <p className="text-muted">{summary}</p>
                          <Link
                            to={`/page-case-detail?id=${id}`}
                            className="link h6"
                          >
                            Read More{" "}
                            <i className="uil uil-angle-right-b align-middle"></i>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </FadeIn>
                </Col>
              ))}
            </Row>
            {/* lightbox for portfolio images */}
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                imagePadding={100}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AllCases;
